import { Box, SimpleGrid } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { IoIosStats } from 'react-icons/io';
import { RiBaseStationLine } from 'react-icons/ri';
import { AllFlags, AllHubs, GetCustomers } from '../../apiService';
import { CustomersDTO, FlagDto, HubDTO } from '../../dto';
import { criticalColor, goodColor, warningColor } from '../../styles';
import { IsOnline } from '../TimeUtils/TimeUtils';
import { HubStatsCard } from './HubsStatsCard';

function OnlinePercent(hubs: HubDTO[]) {
  let onlineHubs = 0;
  hubs.map((hub) => {
    if (IsOnline(hub.last_contact)) {
      onlineHubs += 1;
    }
  });

  if (hubs.length === 0) {
    return 0;
  }

  return (onlineHubs / hubs.length) * 100;
}

export function Status() {
  const [onlinePercentage, setOnlinePercentage] = useState<number>();
  const [hubs, setHubs] = useState<HubDTO[]>();
  const [numberOfHubsWithProblems, setNumberOfHubsWithProblems] = useState<number>();
  const [flags, setFlags] = useState<FlagDto[]>();
  const [customers, setCustomers] = useState<CustomersDTO>();

  useEffect(() => {
    const fetchInfo = () => {
      AllFlags().then((r) => {
        setFlags(r.data);
      });
      AllHubs().then((r) => {
        setHubs(Object.values(r).flat());
      });
      GetCustomers().then((r) => {
        setCustomers(r);
      });
    };
    fetchInfo();
    setInterval(() => fetchInfo(), 60 * 1000);
  }, []);

  useEffect(() => {
    if (hubs) {
      setOnlinePercentage(OnlinePercent(hubs));
    }
    if (flags) {
      setNumberOfHubsWithProblems(
        Array.from(new Set(flags.filter((flag) => flag.is_active).map((flag) => flag.hub))).length,
      );
    }
  }, [flags, hubs]);

  const getStatusColor = () => {
    if (onlinePercentage == undefined) {
      return criticalColor;
    }

    if (onlinePercentage == 100) {
      return goodColor;
    } else if (onlinePercentage >= 90) {
      return warningColor;
    } else {
      return criticalColor;
    }
  };

  const getErrorColor = () => {
    if (numberOfHubsWithProblems == undefined) {
      return criticalColor;
    }

    if (numberOfHubsWithProblems == 0) {
      return goodColor;
    }
    if (numberOfHubsWithProblems == 1) {
      return warningColor;
    }
    if (numberOfHubsWithProblems >= 2) {
      return criticalColor;
    }
  };

  return (
    <Box maxW="7xl" mx={'auto'} px={{ base: 2, sm: 12, md: 17 }}>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
        <HubStatsCard
          title={t('number_of_customers')}
          stat={customers?.meta.total_customers.toString()}
          icon={<IoIosStats />}
        />
        <HubStatsCard title={t('number_of_cores')} stat={hubs?.length.toString()} icon={<IoIosStats />} />
        <HubStatsCard
          title={t('online')}
          stat={onlinePercentage !== undefined ? onlinePercentage.toFixed(1) + '%' : undefined}
          icon={<RiBaseStationLine />}
          color={getStatusColor()}
        />
        <HubStatsCard
          title={t('cores_with_flags')}
          stat={numberOfHubsWithProblems !== undefined ? numberOfHubsWithProblems.toString() : undefined}
          icon={<IoIosStats />}
          color={getErrorColor()}
        />
      </SimpleGrid>
    </Box>
  );
}
