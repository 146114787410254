import { Box, Center, Input, SimpleGrid, Skeleton, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { GetCustomers } from '../../apiService';
import { CustomersDTO } from '../../dto';
import { CustomerCard } from './CustomerCard';

export function AllCustomers() {
  const [customers, setCustomers] = useState<CustomersDTO>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    GetCustomers().then((r) => setCustomers(r));
  }, []);

  return (
    <Skeleton isLoaded={!!customers} height="100%">
      <Box h="100%">
        <Center>
          <VStack width={'100%'}>
            <Text size={'xl'} as={'b'}>
              {t('search')}
            </Text>
            <Input
              type="text"
              placeholder={t('Search_by')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              marginBottom="16px"
              width={{ base: '95%', md: '30%' }}
              minW={'400px'}
            />
          </VStack>
        </Center>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
          {customers &&
            customers.customers.map((customer) => <CustomerCard key={customer.customer_id} customer={customer} />)}
        </SimpleGrid>
      </Box>
    </Skeleton>
  );
}
