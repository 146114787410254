import { Box, Image, Skeleton, Text, useColorMode } from '@chakra-ui/react';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { GetCustomers } from '../../apiService';
import Green from '../../assets/images/circle-dot-green.svg';
import Red from '../../assets/images/circle-dot-red.svg';
import Yellow from '../../assets/images/circle-dot-yellow.svg';
import IQULogo from '../../assets/images/iQU_transparent.png';
import { CustomerMeta, CustomersDTO, MapMarkerDTO } from '../../dto';
import { grayishBlue, whiteColor } from '../../styles';
import { MapMarkerWindow } from '../stats/MapMarkerWindow';
import { IsOnline } from '../TimeUtils/TimeUtils';
import mapStylesDark from './jsonStylingDark.json';
import mapStylesLight from './jsonStylingLight.json';
import './map-style.css';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
  borderRadius: '14px',
};

const iquHQCenter = {
  lat: 60.2553909778811,
  lng: 11.682766697499536,
};

interface MarkerInterface {
  selectedMarker?: string;
  setSelectedMarker?: (marker: string) => void;
  whitelist?: string[];
}

export function MapComponent({ selectedMarker, setSelectedMarker, whitelist }: MarkerInterface) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });
  const isDarkMode = useColorMode().colorMode === 'dark';
  const [markers, setMarkers] = useState<MapMarkerDTO[]>([]);
  const [selectedMarkerData, setSelectedMarkerData] = useState<MapMarkerDTO>();
  const [customers, setCustomers] = useState<CustomersDTO>();

  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState(iquHQCenter);

  const ColorToUse = (customer_meta: CustomerMeta): string => {
    if (customer_meta.has_hub_with_flags && IsOnline(customer_meta.least_recent_last_contact)) {
      return Yellow;
    }
    if (!IsOnline(customer_meta.least_recent_last_contact)) {
      return Red;
    }
    return Green;
  };

  useEffect(() => {
    const customer = customers?.customers.find((c) => c.customer_id === selectedMarker);
    if (customer) {
      setZoom(10);
      setCenter({ lat: Number(customer.latitude), lng: Number(customer.longitude) });
      setSelectedMarkerData(markers.find((m) => m.key === selectedMarker));
    }
  }, [selectedMarker]);

  useEffect(() => {
    GetCustomers().then((data) => {
      setCustomers(data);
      const customers: CustomersDTO = data;
      const customerMarkers = customers.customers
        .filter((customer) => !whitelist || whitelist.length === 0 || whitelist.includes(customer.customer_id))
        .map((customer) => ({
          key: customer.customer_id,
          position: { lat: Number(customer.latitude), lng: Number(customer.longitude) },
          title: customer.company_name,
          icon: {
            url: ColorToUse(customers.meta.customers[customer.customer_id]),
            anchor: { x: 20, y: 20 } as google.maps.Point,
          },
          data: {
            name: customer.company_name,
            id: customer.customer_id,
            location_name: `${customer.street} ${customer.house_number}, ${customer.post_code} ${customer.city}`,
          },
        }));
      setMarkers(customerMarkers);
    });
  }, [whitelist]);

  useEffect(() => {
    document.documentElement.style.setProperty('--background-color', isDarkMode ? '#171923' : '#edf2f7');
  }, [isDarkMode]);

  const handleMarkerClick = (marker: MapMarkerDTO) => {
    if (setSelectedMarker) {
      setSelectedMarker(marker.key);
    }
  };

  if (loadError) {
    return <Text>{t('error_loading_maps')}</Text>;
  }

  return (
    <Skeleton isLoaded={isLoaded} height="100%" width="100%">
      <Box position="relative" w={'100%'} h={'100%'}>
        {isLoaded && (
          <>
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              zIndex="2"
              borderWidth={'4px'}
              borderColor={isDarkMode ? grayishBlue : whiteColor}
              borderRadius={'10px'}
              overflowY={'hidden'}
              pointerEvents={'none'}
            />
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={zoom ? zoom : 6}
              id="searchInputControl"
              options={{
                disableDefaultUI: true,
                zoomControl: false,
                mapTypeControl: false,
                styles: isDarkMode ? mapStylesDark : mapStylesLight,
                streetViewControl: false,
                restriction: {
                  latLngBounds: {
                    north: 85,
                    south: -85,
                    east: 180,
                    west: -180,
                  },
                  strictBounds: false,
                },
                minZoom: 2.2,
              }}
            >
              <Box
                h="24px"
                bg={isDarkMode ? grayishBlue : whiteColor}
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                zIndex="2"
              >
                <Image src={IQULogo} objectFit="contain" width={{ base: '10%', md: '5%' }} height="100%" />
              </Box>
              {markers.map((marker) => (
                <Marker
                  key={marker.key}
                  position={marker.position}
                  title={marker.title}
                  icon={marker.icon}
                  onClick={() => handleMarkerClick(marker)}
                />
              ))}
              {selectedMarker && setSelectedMarker && selectedMarkerData && (
                <InfoWindow position={selectedMarkerData.position} onCloseClick={() => setSelectedMarker('')}>
                  <MapMarkerWindow data={selectedMarkerData.data} />
                </InfoWindow>
              )}
            </GoogleMap>
          </>
        )}
      </Box>
    </Skeleton>
  );
}
