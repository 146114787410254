import { Box, Card, Center, Flex, Grid, Heading } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AllHubs, GetCustomer } from '../../apiService';
import { CustomerHubs } from '../../components/customer/CustomerHubs';
import { CustomerInfo } from '../../components/customer/CustomerInfo';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { MapComponent } from '../../components/map/MapComponent';
import { TaskCard } from '../../components/task/TaskCard';
import { TaskLog } from '../../components/task/TaskLog';
import { CustomerDataDTO, HubsDTO } from '../../dto';

export function ManageCustomer() {
  const [customer, setCustomer] = useState<CustomerDataDTO>();
  const { customer_id } = useParams<{ customer_id: string; hub_type: string }>();
  const [hubs, setHubs] = useState<HubsDTO>();

  useEffect(() => {
    if (customer_id) {
      GetCustomer(customer_id).then((res) => {
        setCustomer(res.customer);
      });
    }
  }, [customer_id]);

  useEffect(() => {
    if (customer_id) {
      AllHubs(customer_id).then((r) => setHubs(r));
    }
  }, [customer_id]);

  if (!customer || !hubs) {
    return <LoadingSpinner />;
  }

  return (
    <Grid
      h={'85vh'}
      gridTemplateColumns={{
        base: '100%',
        md: '4fr 5fr 4fr',
        sm: '1fr 1fr 1fr',
      }}
      gridTemplateRows={{
        base: '100%',
        sm: '3vh 25vh 42vh',
        md: '1fr 10fr 10fr ',
      }}
      gridTemplateAreas={{
        base: `
      'info_card'
      'hubs_card'
      'map'`,
        sm: `
      'info_card'
      'hubs_card'
      'map'`,
        md: `
      'header header header'
      'add_task_card info_card hubs_card'
      'task_card map hubs_card'`,
      }}
      gap={8}
      rowGap={30}
    >
      <Flex gridArea="header">
        <Heading width={'100%'} alignItems={'center'}>
          {customer.company_name}
        </Heading>
      </Flex>
      <Flex gridArea="hubs_card">
        <Card width={'100%'} h={'100%'}>
          <CustomerHubs hubs={hubs} />
        </Card>
      </Flex>
      <Flex gridArea="info_card">
        <Card width={'100%'} h={'100%'}>
          <CustomerInfo customer={customer} />
        </Card>
      </Flex>
      <Flex gridArea="add_task_card">
        <Card width={'100%'} h={'100%'}>
          <Center>
            <Heading size="lg">{t('add_task_uppercase')}</Heading>
          </Center>
          <TaskCard id={customer.customer_id} type={'customermodel'} />
        </Card>
      </Flex>
      <Box gridArea="map">
        <Card width={'100%'} h={'100%'}>
          <MapComponent whitelist={[customer.customer_id]} />
        </Card>
      </Box>
      <Flex gridArea="task_card">
        <Card width={'100%'} h={'100%'} align={'center'}>
          <TaskLog id={customer_id} />
        </Card>
      </Flex>
    </Grid>
  );
}
