import { Box, chakra, Flex, Heading, Icon } from '@chakra-ui/react';
import { MdLocationOn, MdOutlineHub } from 'react-icons/md';
import { PiKeyboardBold } from 'react-icons/pi';
import { MapMarkerWindowDTO } from '../../dto';

interface HubInfoWindowInterface {
  data: MapMarkerWindowDTO;
}

export function MapMarkerWindow({ data }: HubInfoWindowInterface) {
  return (
    <Box
      w="100%"
      mx="auto"
      bg="white"
      _dark={{
        bg: 'gray.800',
      }}
      roundedBottom="lg"
      overflow="hidden"
    >
      <Flex
        alignItems="center"
        px={6}
        py={3}
        bg="gray.100"
        _dark={{
          bg: 'gray.900',
        }}
      >
        <Icon as={MdOutlineHub} h={6} w={6} />
        <Heading mx={3} fontWeight="bold" fontSize="lg">
          {data.name}
        </Heading>
      </Flex>
      <Box py={4} px={6}>
        <Flex alignItems="center" mt={4}>
          <Icon as={PiKeyboardBold} h={6} w={6} mr={2} />
          <Heading fontSize="sm" px={2}>
            {data.id}
          </Heading>
        </Flex>
        <Flex alignItems="center" mt={4}>
          <Icon as={MdLocationOn} h={6} w={6} mr={2} />
          <chakra.h1 px={2} fontSize="sm">
            {data.location_name}
          </chakra.h1>
        </Flex>
      </Box>
    </Box>
  );
}
