import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  AllHubs,
  DeleteCustomer,
  DeleteHub,
  DeleteHubPermission,
  GetAllHubPermissions,
  GetCustomers,
  GetUserAPI,
  PostCustomer,
  PostHub,
  PostHubPermission,
} from '../../apiService';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { CoreDTO, CustomerDataDTO, SicDTO, UserPermissionsDataDTO } from '../../dto';

type ModelConfig<T> = {
  idKey: keyof T;
  nameKey: keyof T;
  deleteFunc: (id: string) => Promise<string>;
  getData: () => Promise<T[]>;
  addItem?: () => Promise<T>;
};

function createModelConfig<T>(config: ModelConfig<T>): ModelConfig<T> {
  return config;
}

const modelConfigs = {
  customer: createModelConfig<CustomerDataDTO>({
    idKey: 'customer_id',
    nameKey: 'company_name',
    deleteFunc: DeleteCustomer,
    getData: async () => (await GetCustomers()).customers,
    addItem: async () => await PostCustomer(),
  }),
  hubpermission: createModelConfig<UserPermissionsDataDTO>({
    idKey: 'id',
    nameKey: 'user',
    deleteFunc: DeleteHubPermission,
    getData: async () => await GetAllHubPermissions(),
    addItem: async () => await PostHubPermission(),
  }),
  core: createModelConfig<CoreDTO>({
    idKey: 'hub_id',
    nameKey: 'hub_name',
    deleteFunc: DeleteHub,
    getData: async () => (await AllHubs()).coremodel,
    addItem: async () => await PostHub('core'),
  }),
  sic: createModelConfig<SicDTO>({
    idKey: 'hub_id',
    nameKey: 'hub_name',
    deleteFunc: DeleteHub,
    getData: async () => (await AllHubs()).sicmodel,
    addItem: async () => await PostHub('sic'),
  }),
};

type ModelData<C> = C extends ModelConfig<infer T> ? T : never;

function getModelConfig<M extends keyof typeof modelConfigs>(model: M): (typeof modelConfigs)[M] {
  return modelConfigs[model];
}

type AllDataTypes = CustomerDataDTO | UserPermissionsDataDTO | CoreDTO | SicDTO;

export function ModelsPage() {
  const { model } = useParams<{ model: string }>();
  const [data, setData] = useState<AllDataTypes[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState<AllDataTypes | null>(null);

  const modelExists = model && model in modelConfigs;

  useEffect(() => {
    if (!modelExists) return;
    const config = getModelConfig(model as keyof typeof modelConfigs);
    async function fetchData() {
      const d = await config.getData();
      if (model === 'hubpermission') {
        const hubPermissionConfig = config as ModelConfig<UserPermissionsDataDTO>;
        const typedData = d as UserPermissionsDataDTO[];
        const updatedData = await Promise.all(
          typedData.map(async (entry) => {
            const user = await GetUserAPI(entry[hubPermissionConfig.nameKey] as string);
            return {
              ...entry,
              [hubPermissionConfig.nameKey]: `${user?.first_name} ${user?.last_name}`,
            };
          }),
        );
        setData(updatedData);
      } else {
        setData(d);
      }
    }
    fetchData();
  }, [model, modelExists]);

  if (!modelExists) {
    return <LoadingSpinner />;
  }

  const modelKey = model as keyof typeof modelConfigs;
  const config = getModelConfig(modelKey);
  type DataType = ModelData<typeof config>;
  const typedData = data as DataType[];

  const handleDeleteClick = (item: DataType) => {
    setSelectedItem(item);
    onOpen();
  };

  const handleConfirmDelete = async () => {
    if (!selectedItem) return;
    const item = selectedItem as DataType;
    const id = item[config.idKey as keyof DataType] as DataType[keyof DataType];
    await config.deleteFunc(id);
    setData((prevData) => (prevData as DataType[]).filter((i) => i[config.idKey as keyof DataType] !== id));
    onClose();
  };

  const handleAddItem = async () => {
    if (!config.addItem) return;
    let newItem = await config.addItem();
    if (model === 'hubpermission') {
      const hubPermissionConfig = config as ModelConfig<UserPermissionsDataDTO>;
      const user = await GetUserAPI((newItem as UserPermissionsDataDTO)[hubPermissionConfig.nameKey] as string);
      newItem = {
        ...newItem,
        [hubPermissionConfig.nameKey]: `${user?.first_name} ${user?.last_name}`,
      };
    }
    setData((prevData) => [newItem, ...prevData] as DataType[]);
  };

  return (
    <>
      {config.addItem && <Button onClick={handleAddItem}>{t('add_new')}</Button>}
      <Grid templateColumns="repeat(auto-fill, minmax(350px, 1fr))" gap={4} mt={2}>
        {typedData.map((entry, index) => {
          const entryId = entry[config.idKey as keyof DataType] as DataType[keyof DataType];
          const entryName = entry[config.nameKey as keyof DataType] as string;
          return (
            <Card key={index} h="300px" w="350px">
              <CardHeader>
                <Center w="100%">
                  <Heading>{entryName}</Heading>
                </Center>
              </CardHeader>
              <CardBody>
                <Center w="100%">
                  <Text>{entryId}</Text>
                </Center>
              </CardBody>
              <CardFooter>
                <Center w="100%" gap={4}>
                  <Link to={`/admin/editor/${model}/${entryId}`}>
                    <Button w="150px" h="50px">
                      {t('edit')}
                    </Button>
                  </Link>
                  <Button w="150px" h="50px" onClick={() => handleDeleteClick(entry)}>
                    {t('delete')}
                  </Button>
                </Center>
              </CardFooter>
            </Card>
          );
        })}
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('confirm_delete')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t('this_action_cannot_be_undone')}</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleConfirmDelete}>
              {t('delete')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
