import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Modal,
  ModalContent,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { GetHubPermissions, GetProfileAdminPicture, GetUserAPI } from '../../apiService';
import { AdminUserViewDTO, HubDTO, HubsPermissionsDTO } from '../../dto';
import { iquColor } from '../../styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { UserCardAdmin } from './user/UserCardAdmin';

interface AssignToHubInterface {
  hub: HubDTO;
}

interface PermissionsCardInterface {
  permission: HubsPermissionsDTO;
}

interface ContactCardInterface {
  user_id: string;
}

function ConactCard({ user_id }: ContactCardInterface) {
  const [user, setUser] = useState<AdminUserViewDTO>();
  useEffect(() => {
    GetUserAPI(user_id).then((r) => setUser(r));
  }, [user_id]);

  if (!user) {
    return <LoadingSpinner />;
  }
  return <UserCardAdmin user={user} />;
}

export function PermissionsCard({ permission }: PermissionsCardInterface) {
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    GetProfileAdminPicture(permission.user__id.toString()).then((r) => {
      setProfilePicture(r);
    });
  }, [permission.user__id]);

  return (
    <Card boxShadow="md">
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ConactCard user_id={permission.user__id} />
        </ModalContent>
      </Modal>
      <CardBody onClick={onOpen}>
        <HStack spacing={'10px'}>
          {profilePicture ? <Avatar size={'md'} src={profilePicture} /> : <Avatar size={'md'} bg={iquColor} />}
          <Text>
            {permission.user__first_name} {permission.user__last_name}
          </Text>
        </HStack>
      </CardBody>
    </Card>
  );
}

export function AssignToHub({ hub }: AssignToHubInterface) {
  const [permissions, setPermissions] = useState<[HubsPermissionsDTO]>();

  useEffect(() => {
    GetHubPermissions(hub.hub_id).then((res) => {
      setPermissions(res);
    });
  }, [hub.hub_id]);

  if (!permissions || !permissions) {
    return <LoadingSpinner />;
  }

  return (
    <Card>
      <CardHeader>
        <Text>
          {t('contacts_for')} {hub.hub_name}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray',
            borderRadius: '24px',
          },
        }}
      >
        <SimpleGrid minChildWidth={'30vh'} spacing={'10px'}>
          {permissions.map((permission, key) => {
            return <PermissionsCard key={key} permission={permission} />;
          })}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}
