import { Box, Card, CardBody, CardHeader, Center, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetCustomer } from '../../../apiService';
import { CustomerDataDTO, HubDTO } from '../../../dto';
import { IsOnline } from '../../TimeUtils/TimeUtils';

interface HubBoxCardInterface {
  hub: HubDTO;
}

export function HubInfoCard({ hub }: HubBoxCardInterface) {
  const [customer, setCustomer] = useState<CustomerDataDTO>();

  useEffect(() => {
    GetCustomer(hub.customer).then((res) => {
      setCustomer(res.customer);
    });
  }, []);

  return (
    <Card width={'100%'}>
      <CardHeader>
        <Center>
          <VStack>
            <Heading as={'h1'}>{t('info_uppercase')}</Heading>
            <Text size={'s'}>{IsOnline(hub.last_contact) ? t('online') : t('offline')}</Text>
            <Text fontSize="sm" color="gray.500">
              ID: {hub.hub_id}
            </Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider paddingTop={'2px'} paddingBottom={'2px'} />
      <CardBody>
        <HStack w={'100%'} h={'100%'}>
          <Box w={'50%'} h={'100%'}>
            <Text>
              {t('name')}: {hub.hub_name}
            </Text>
            <Text>
              {t('ip')}: {hub.ipaddress}
            </Text>
          </Box>
          <Box w={'50%'} h={'100%'}>
            <HStack>
              <Text>
                {t('customer')}
                {': '}
              </Text>
              <Link to={`/customers/${customer?.customer_id}`}>
                <Text textDecoration="underline">{customer?.company_name}</Text>
              </Link>
            </HStack>
          </Box>
        </HStack>
      </CardBody>
    </Card>
  );
}
