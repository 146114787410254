import { Box, FormLabel, Heading, Select, useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AllHubs,
  AllUserAPI,
  GetCustomer,
  GetCustomers,
  GetHubPermission,
  GetSchema,
  HubAPI,
  PutCustomer,
  PutHubPermission,
  UpdateHub,
} from '../../apiService';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { SchemaModelEditor } from '../../components/utils/SchemaModelEditor';
import { AdminUserViewDTO, CustomerDataDTO, HubsDTO, UserPermissionsDataDTO } from '../../dto';

export function ModelEditor() {
  const { model, model_id } = useParams<{ model: string; model_id: string }>();
  const [schema, setSchema] = useState<{
    properties: Record<string, unknown>;
    $defs: Record<string, unknown>;
  }>();
  const [data, setData] = useState<Record<string, unknown>>();
  const toast = useToast();
  const navigate = useNavigate();

  const [customers, setCustomers] = useState<CustomerDataDTO[]>();
  const [users, setUsers] = useState<[AdminUserViewDTO]>();
  const [hubs, setHubs] = useState<HubsDTO>();

  useEffect(() => {
    if (model && model_id) {
      GetSchema(model).then((r) => {
        setSchema(r);
      });
      GetData(model, model_id).then((r) => {
        setData(r);
      });
    }

    if (model === 'core' || model === 'sic') {
      GetCustomers().then((r) => {
        setCustomers(Object.values(r.customers));
      });
    }
    if (model === 'hubpermission') {
      AllUserAPI().then((res) => {
        setUsers(res);
      });
      AllHubs().then((res) => {
        setHubs(res);
      });
    }
  }, []);

  if (!schema || !data || !model || !model_id) {
    return <LoadingSpinner />;
  }

  return (
    <Box p={8}>
      <Heading>
        {t('editing')} {model}
      </Heading>
      <SchemaModelEditor
        schema={schema}
        data={data}
        setData={setData}
        onSave={async () => {
          return OnSave(model, model_id, data)
            .then((r) => {
              toast({
                title: t('data_saved'),
                description: t('data_saved_description'),
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
              navigate('/admin/editor/' + model + '/');
              return r;
            })
            .catch((e) => {
              toast({
                title: 'Error.',
                description: 'Failed to save data.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              return e;
            });
        }}
        onCancel={() => {
          navigate('/admin/editor/' + model + '/');
        }}
        useTabs={false}
        blackList={['last_contact', 'last_edit', 'date_added']}
        field_override={{
          customer: (
            <Box>
              <FormLabel>{t('customer')}</FormLabel>
              <Select
                value={(data as CustomerDataDTO).customer}
                onChange={(e) => setData({ ...data, customer: e.target.value })}
              >
                {customers &&
                  customers.map((customer, index) => (
                    <option key={index} value={customer.customer_id}>
                      {customer.company_name} - {customer.customer_id}
                    </option>
                  ))}
              </Select>
            </Box>
          ),
          user: (
            <Box>
              <FormLabel>{t('user')}</FormLabel>
              <Select
                value={(data as UserPermissionsDataDTO).user}
                onChange={(e) => setData({ ...data, user: e.target.value })}
              >
                {users &&
                  users.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.first_name} {user.last_name} - {user.id}
                    </option>
                  ))}
              </Select>
            </Box>
          ),
          hub: (
            <Box>
              <FormLabel>{t('hub')}</FormLabel>
              <Select
                value={(data as UserPermissionsDataDTO).hub}
                onChange={(e) => setData({ ...data, hub: e.target.value })}
              >
                {hubs &&
                  Object.values(hubs).map((hub_list) =>
                    Object.values(hub_list).map((hub, index) => (
                      <option key={index} value={hub.hub_id}>
                        {hub.hub_name} - {hub.hub_id}
                      </option>
                    )),
                  )}
              </Select>
            </Box>
          ),
        }}
      />
    </Box>
  );
}

async function GetData(model: string, model_id: string): Promise<Record<string, unknown>> {
  switch (model) {
    case 'customer':
      return await GetCustomer(model_id).then((r) => {
        return r.customer;
      });
    case 'hubpermission':
      return await GetHubPermission(model_id).then((r) => {
        return r;
      });
    case 'core':
      return HubAPI(model_id);
    case 'sic':
      return HubAPI(model_id);
    default:
      throw new Error('Model not found');
  }
}

async function OnSave(model: string, model_id: string, data: Record<string, unknown>): Promise<string> {
  let response;
  switch (model) {
    case 'customer':
      response = await PutCustomer(model_id, data);
      break;
    case 'hubpermission':
      response = await PutHubPermission(model_id, data);
      break;
    case 'core':
      response = await UpdateHub(model_id, data);
      break;
    case 'sic':
      response = await UpdateHub(model_id, data);
      break;
    default:
      throw new Error('Model not found');
  }

  if (!response) {
    throw new Error('Failed to save data');
  }

  return response;
}
