import { VStack } from '@chakra-ui/react';
import { HubsDTO } from '../../dto';
import { HubCard } from '../hub/HubCard';

interface CustomerHubsInterface {
  hubs: HubsDTO;
}

export function CustomerHubs({ hubs }: CustomerHubsInterface) {
  return (
    <VStack align="stretch" spacing={4} p={4} overflow={'auto'}>
      {Object.entries(hubs).map(
        ([key, hubList]) =>
          hubList.length > 0 && hubList.map((hub) => <HubCard key={hub.hub_id} hub={hub} hub_type={key} />),
      )}
    </VStack>
  );
}
