import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { t } from 'i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { HubDTO } from '../../dto';

interface StatusHubIconInterface {
  hub: HubDTO;
}

export function IsOnline(last_contact: string | number | Date) {
  return (new Date().getTime() - new Date(last_contact).getTime()) / 1000 <= 120;
}

export function StatusHubIcon({ hub }: StatusHubIconInterface) {
  const lastConnection = new Date(hub.last_contact);
  const today = new Date();
  let color = 'green';

  const timeSinceContactSeconds = (today.getTime() - lastConnection.getTime()) / 1000;

  if (timeSinceContactSeconds > 20) {
    color = 'orange';
  }

  if (timeSinceContactSeconds > 120) {
    color = 'red';
  }

  function ConvertSecToDay(n: number) {
    const day = parseInt(String(n / (24 * 3600)));

    n = n % (24 * 3600);
    const hour = parseInt(String(n / 3600));

    n %= 3600;
    const minutes = n / 60;

    n %= 60;
    const seconds = n;

    return (
      day +
      ' ' +
      t('days_lowercase') +
      ' ' +
      hour +
      ' ' +
      t('hours_lowercase') +
      ' ' +
      minutes.toFixed() +
      ' ' +
      t('minutes') +
      ' ' +
      seconds.toFixed() +
      ' ' +
      t('seconds_lowercase') +
      ' '
    );
  }

  return (
    <Box top={0} right={0} position={'absolute'}>
      <Tooltip label={t('last_contact') + ': ' + ConvertSecToDay(timeSinceContactSeconds) + t('ago_lowercase')}>
        <IconButton aria-label={t('connection')} bg={color} color={'white'} icon={<AiOutlineInfoCircle />} />
      </Tooltip>
    </Box>
  );
}
