import { Box, Card, Flex, Grid, Heading, IconButton, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { IoMdHelpCircle } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { HubAPI } from '../../apiService';
import { AssignToHub } from '../../components/admin/AssignToHub';
import { FlagBox } from '../../components/flags/FlagBox';
import { HubInfoCard } from '../../components/hub/info/HubInfoCard';
import { PopoverComponent } from '../../components/hub/info/Popover';
import { MapComponent } from '../../components/map/MapComponent';
import { TaskCard } from '../../components/task/TaskCard';
import { TaskLog } from '../../components/task/TaskLog';
import { HubDTO } from '../../dto';
import { iquColor } from '../../styles';

interface HubBoxCardInterface {
  hub: HubDTO;
  hub_type: string;
}

function HubBoxCard({ hub, hub_type }: HubBoxCardInterface) {
  return (
    <Grid
      h={'85vh'}
      gridTemplateColumns={{
        base: '100%',
        md: '4fr 5fr 4fr',
        sm: '1fr 1fr 1fr',
      }}
      gridTemplateRows={{
        base: '100%',
        sm: '3vh 25vh 25vh 20vh 20vh',
        md: '1fr 10fr 10fr',
      }}
      gridTemplateAreas={{
        base: `
        'header'
        'map'
        'middlecard'
        'leftcard'
        'permissions'
        'leftscrollcard'`,
        sm: `
        'header'
        'map'
        'permissions'
        'middlecard'
        'rightcard'
        'leftcard'
        'leftscrollcard'`,
        md: `
        'header header header'
        'add_task_card info_card contacts_card'
        'task_card map flag_card'`,
      }}
      gap={8}
      rowGap={30}
    >
      <Flex gridArea="header">
        <Heading width={'100%'} alignItems={'center'}>
          {hub.hub_name}
        </Heading>
      </Flex>
      <>
        <Flex w={'100%'} gridArea="contacts_card">
          <Card width={'100%'} overflowY="auto">
            <AssignToHub hub={hub} />
          </Card>
        </Flex>
        <Flex gridArea="flag_card">
          <Card width={'100%'}>
            <FlagBox hub={hub} />
          </Card>
        </Flex>
      </>
      <Box gridArea="map">
        <Card w={'100%'} h={'100%'}>
          <MapComponent whitelist={[hub.customer]} />
        </Card>
      </Box>
      <Flex gridArea="add_task_card">
        <Card width={'100%'}>
          <Flex w={'100%'} justifyContent={'center'} mt={4} gap={4}>
            <Heading>{t('add_task_uppercase')}</Heading>
            <PopoverComponent
              PopoverBodyText={t('add_task_info')}
              PopoverHeaderText={t('add_task')}
              PopoverTriggerComponent={
                <IconButton
                  aria-label={'Add task info'}
                  icon={<IoMdHelpCircle size={'32px'} />}
                  bg={'none'}
                  color={iquColor}
                />
              }
            />
          </Flex>
          <TaskCard id={hub.hub_id} type={hub_type} />
        </Card>
      </Flex>
      <Flex gridArea="task_card">
        <Card width={'100%'} align={'center'}>
          <TaskLog id={hub.hub_id} />
        </Card>
      </Flex>
      <Flex gridArea="info_card">
        <HubInfoCard hub={hub} />
      </Flex>
    </Grid>
  );
}

export function ManageHub() {
  const [hub, setHub] = useState<HubDTO>();
  const { hub_id, hub_type } = useParams<{ hub_id: string; hub_type: string }>();

  useEffect(() => {
    if (hub_id) {
      HubAPI(hub_id).then((res) => {
        setHub(res);
      });
    }
  }, [hub_id]);

  if (!hub || !hub_type) {
    return <Text>{t('core_not_found')}</Text>;
  }

  return <HubBoxCard hub={hub} hub_type={hub_type} />;
}
