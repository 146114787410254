import axios, { AxiosResponse } from 'axios';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import {
  AdminUserViewDTO,
  CustomerDTO,
  CustomersDTO,
  FlagDataDTO,
  FlagDto,
  HubDTO,
  HubsDTO,
  HubsPermissionsDTO,
  HubTypesWithFlag,
  InviteByEmailDTO,
  LoginDto,
  NotificationDTO,
  TaskDTO,
  TwoFactorDto,
  UpdateInfoDTO,
  UpdaterDTO,
  UserDTO,
  UserInvitesDto,
  UserPermissionsDataDTO,
  UserPermissionsDTO,
} from './dto';

const isDevelopment = process.env.REACT_APP_DEVELOPMENT === 'true';

const getBaseUrlDev = () => {
  return 'http://localhost:8000/';
};

const getBaseUrlProd = () => {
  return 'https://gw-backend.iqu.no/';
};

export function baseUrl() {
  if (isDevelopment) {
    return getBaseUrlDev();
  } else {
    return getBaseUrlProd();
  }
}

const AuthHeader = () => {
  return { Authorization: 'Bearer ' + Cookies.get('accessToken') };
};

export async function Login(userName: string, password: string): Promise<LoginDto> {
  const response = await axios.post<LoginDto>(baseUrl() + 'api/token/', {
    username: userName,
    password: password,
  });
  return response.data;
}

export async function TwoFactorAuthAPI(code: string): Promise<number> {
  const response = await axios.post<TwoFactorDto>(
    baseUrl() + 'api/two-factor-auth/',
    {
      code: code,
    },
    {
      headers: AuthHeader(),
    },
  );
  return response.status;
}

export async function VerifyToken(): Promise<boolean> {
  try {
    const response = await axios.post(baseUrl() + 'api/token/verify/', { token: Cookies.get('accessToken') });
    return response.status == 200;
  } catch (error) {
    return false;
  }
}

export async function IsTwoFactorAuthAPI(): Promise<boolean> {
  try {
    const response = await axios.get(baseUrl() + 'api/two-factor-auth/', { headers: AuthHeader() });
    return response.status === 200;
  } catch (error) {
    return false;
  }
}

export async function AllHubs(customerId?: string): Promise<HubsDTO> {
  const params = customerId ? { customer_id: customerId } : {};

  const response = await axios.get(baseUrl() + 'api/hub/', {
    headers: AuthHeader(),
    params,
  });

  return response.data;
}

export async function GetCustomers(): Promise<CustomersDTO> {
  const response = await axios.get(baseUrl() + 'api/customer/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetCustomer(customer_id: string): Promise<CustomerDTO> {
  const response = await axios.get(baseUrl() + `api/customer/${customer_id}`, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetHubPermission(model_id: string): Promise<UserPermissionsDataDTO> {
  const response = await axios.get(baseUrl() + `api/user-permissions/${model_id}/`, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetUpdaters(): Promise<UpdaterDTO[]> {
  const response = await axios.get(baseUrl() + 'api/updater', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function HubAPI(hubId: string): Promise<HubDTO> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hubId, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function UserAPI(): Promise<UserDTO> {
  const response = await axios.get(baseUrl() + 'api/me/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function AllUserAPI(): Promise<[AdminUserViewDTO]> {
  const response = await axios.get(baseUrl() + 'api/user/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetUserAPI(user_id: string): Promise<AdminUserViewDTO> {
  const response = await axios.get(baseUrl() + 'api/user/' + user_id, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function AllFlags(queryString: string = ''): Promise<FlagDataDTO> {
  const response = await axios.get(baseUrl() + `api/flag/${queryString}`, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetHubFlagsAPI(hub_id: string): Promise<FlagDto> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/flag/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetFlagAPI(hub_id: string, flag_id: string): Promise<FlagDto> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/flag/' + flag_id, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetHubWithFlags(): Promise<HubTypesWithFlag> {
  const response = await axios.get(baseUrl() + 'api/hubs/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function DeactivateFlag(hub_id: string, flag_id: number): Promise<FlagDto[]> {
  const response = await axios.put(
    baseUrl() + 'api/hub/' + hub_id + '/flag/' + flag_id + '/',
    { active: false },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function SendHubCommand(hub_id: string, command: string): Promise<AxiosResponse<unknown, unknown>> {
  return await axios.post(
    baseUrl() + 'api/commands/' + hub_id + '/',
    { command: command },
    {
      headers: {
        ...AuthHeader(),
      },
    },
  );
}
export async function GetConfigFileStatus(hub_id: string, filename: string) {
  const response = await axios.get(baseUrl() + 'api/commands/' + hub_id + '/config/status?filename=' + filename, {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetConfigFile(hub_id: string, filename: string) {
  return await axios.get(baseUrl() + 'api/commands/' + hub_id + '/config?filename=' + filename, {
    headers: AuthHeader(),
    responseType: 'blob',
  });
}

export async function PatchConfigFiles(hub_id: string, files: Record<string, File>) {
  const formData = new FormData();
  Object.entries(files).forEach(([key, value]) => formData.append(key, value));

  return await axios.patch(baseUrl() + 'api/commands/' + hub_id + '/config/', formData, {
    headers: {
      ...AuthHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function UpdateHub(hub_id: string, data: unknown): Promise<number> {
  const response = await axios.put(baseUrl() + 'api/hub/' + hub_id + '/', { data }, { headers: AuthHeader() });
  return response.status;
}

export async function SendUserInvite(email: string): Promise<number> {
  const response = await axios.post(baseUrl() + 'api/user-invite/', { email: email }, { headers: AuthHeader() });
  return response.status;
}

export async function UpdateUserInvite(email: string, time: number): Promise<number> {
  const response = await axios.put(
    baseUrl() + 'api/user-invite/',
    { email: email, time: time },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function DeleteUserInvite(email: string): Promise<number> {
  const response = await axios.delete(baseUrl() + 'api/user-invite/', {
    headers: AuthHeader(),
    data: { email: email },
  });
  return response.status;
}

export async function GetUserInvites(): Promise<UserInvitesDto[]> {
  const response = await axios.get(baseUrl() + 'api/user-invite/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetCreateUser(inviteId: string): Promise<InviteByEmailDTO> {
  const response = await axios.get(baseUrl() + 'api/invite/' + inviteId + '/');
  return response.data;
}

export async function GetHubPermissions(hub_id: string): Promise<[HubsPermissionsDTO]> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/permissions/', { headers: AuthHeader() });
  return response.data;
}

export async function PostHubPermissions(
  hub_id: string,
  user_id: string,
  can_delete: boolean,
  update: boolean,
  view: boolean,
  add_users: boolean,
): Promise<number> {
  const data = {
    user_id: user_id,
    delete: can_delete,
    update: update,
    view: view,
    add_users: add_users,
  };
  const response = await axios.post(
    baseUrl() + 'api/hub/' + hub_id + '/permissions/',
    { data },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function GetProfilePicture(): Promise<string | null> {
  try {
    const response = await axios.get(baseUrl() + 'api/me/picture/', { headers: AuthHeader(), responseType: 'blob' });
    if (response.status === 404) {
      return null;
    }
    return URL.createObjectURL(response.data);
  } catch (error) {
    return null;
  }
}

export async function GetProfileAdminPicture(user_id: string): Promise<string | null> {
  try {
    const response = await axios.get(baseUrl() + 'api/user/' + user_id + '/picture', {
      headers: AuthHeader(),
      responseType: 'blob',
    });
    if (response.status === 404) {
      return null;
    }
    return URL.createObjectURL(response.data);
  } catch (error) {
    return null;
  }
}

export async function GetUserAdminPermissions(user_id: string): Promise<[UserPermissionsDTO]> {
  const response = await axios.get(baseUrl() + 'api/user/' + user_id + '/permissions/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function UploadProfilePicture(imageFile: File): Promise<string> {
  const formData = new FormData();
  formData.append('profile_picture', imageFile);

  const response = await axios.post(baseUrl() + 'api/me/picture/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + Cookies.get('accessToken'),
    },
    responseType: 'blob',
  });
  return URL.createObjectURL(response.data);
}

export async function CreateUserAPI(
  inviteId: string,
  username: string,
  phone_number: string,
  password: string,
  first_name: string,
  last_name: string,
): Promise<number> {
  const response = await axios.post(baseUrl() + 'api/invite/' + inviteId + '/', {
    username: username,
    phone_number: phone_number,
    password: password,
    first_name: first_name,
    last_name: last_name,
  });
  return response.status;
}

export async function GetNotifications(): Promise<NotificationDTO[]> {
  const response = await axios.get(baseUrl() + 'api/notifications/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function ViewedNotification(id: number, viewed: boolean): Promise<NotificationDTO> {
  const response = await axios.put(
    baseUrl() + 'api/notification/' + id + '/',
    { viewed: viewed },
    {
      headers: AuthHeader(),
    },
  );
  return response.data;
}

export async function ViewedNotifications(notification_ids: number[], viewed: boolean): Promise<NotificationDTO> {
  const response = await axios.put(
    baseUrl() + 'api/notifications/',
    { notification_ids: notification_ids, viewed: viewed },
    {
      headers: AuthHeader(),
    },
  );
  return response.data;
}

export async function GetVersion() {
  const response = await axios.get(baseUrl() + 'api/version');
  return response.data;
}

export async function GetUpdateInfo(hub_id: string): Promise<UpdateInfoDTO> {
  const response = await axios.get(baseUrl() + 'api/hub/' + hub_id + '/update/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetCommands(model: string): Promise<string[]> {
  const response = await axios.get(baseUrl() + 'api/commands-definitions/' + model + '/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetCommandsLog(id: string): Promise<TaskDTO[]> {
  const response = await axios.get(baseUrl() + 'api/commands/' + id + '/', {
    headers: AuthHeader(),
  });
  return response.data;
}

export async function GetSchema(schema: string): Promise<
  | {
      properties: Record<string, unknown>;
      $defs: Record<string, unknown>;
    }
  | undefined
> {
  let response;

  switch (schema) {
    case 'customer':
      response = await axios.get(baseUrl() + 'api/customer-schema/', {
        headers: AuthHeader(),
      });
      break;
    case 'hubpermission':
      response = await axios.get(baseUrl() + 'api/hub-permission-schema/', {
        headers: AuthHeader(),
      });
      break;
    case 'core':
      response = await axios.get(baseUrl() + 'api/hub-schema/core/', {
        headers: AuthHeader(),
      });
      break;
    case 'sic':
      response = await axios.get(baseUrl() + 'api/hub-schema/sic/', {
        headers: AuthHeader(),
      });
      break;
  }

  if (response) {
    return response.data;
  }
}

export function TaskLogEventSource(id: string): EventSource {
  return new EventSource(`${baseUrl()}/api/commands-stream/${id}/`);
}

export async function PutCustomer(id: string, data: unknown) {
  const response = await axios.put(baseUrl() + 'api/customer/' + id + '/', data, { headers: AuthHeader() });
  return response.data;
}

export async function DeleteCustomer(id: string) {
  const response = await axios.delete(baseUrl() + 'api/customer/' + id + '/', { headers: AuthHeader() });
  return response.data;
}

export async function DeleteHubPermission(id: string) {
  const response = await axios.delete(baseUrl() + 'api/user-permissions/' + id + '/', { headers: AuthHeader() });
  return response.data;
}

export async function DeleteHub(id: string) {
  const response = await axios.delete(baseUrl() + 'api/hub/' + id + '/', { headers: AuthHeader() });
  return response.data;
}

export async function PutHubPermission(id: string, data: unknown) {
  const response = await axios.put(baseUrl() + 'api/user-permissions/' + id + '/', data, { headers: AuthHeader() });
  return response.data;
}

export async function PostCustomer() {
  const response = await axios.post(
    baseUrl() + 'api/customer/',
    { company_name: t('new_customer') },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function PostHub(hub_type: string) {
  const response = await axios.post(
    baseUrl() + 'api/create-hub/' + hub_type + '/',
    { hub_name: t(`new_${hub_type}`) },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function PostHubPermission() {
  const response = await axios.post(baseUrl() + 'api/user-permissions/', {}, { headers: AuthHeader() });
  return response.data;
}

export async function GetAllHubPermissions(): Promise<[UserPermissionsDataDTO]> {
  const response = await axios.get(baseUrl() + 'api/user-permissions/', { headers: AuthHeader() });
  return response.data;
}
