import { Box, SimpleGrid, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { AllCustomers } from '../../components/customer/AllCustomers';
import { MapComponent } from '../../components/map/MapComponent';

export function HomePage() {
  const [selectedMarker, setSelectedMarker] = useState<string>();

  return (
    <SimpleGrid columns={[1, 1, 2]} spacing={8}>
      <Box
        w={'100%'}
        order={{ base: '1', md: '0' }}
        alignItems="center"
        h={{ base: '44vh', md: '88vh' }}
        overflow="hidden"
      >
        <VStack h="100%">
          <Box
            className="custom-scrollbar"
            flexWrap="wrap"
            justifyContent="center"
            alignContent="flex-start"
            h="100%"
            overflowY="auto"
            w={'100%'}
          >
            <AllCustomers />
          </Box>
        </VStack>
      </Box>

      <MapComponent setSelectedMarker={setSelectedMarker} selectedMarker={selectedMarker} />
    </SimpleGrid>
  );
}
