import { FlagDto } from '../../dto';
import { Badge, Button, Card, CardBody, SimpleGrid, Text } from '@chakra-ui/react';
import { criticalColor, debugColor, errorColor, infoColor, warningColor } from '../../styles';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

interface SmallFlagCardInterface {
  flag: FlagDto;
}

export function SmallFlagCard({ flag }: SmallFlagCardInterface) {
  const levelColorMap = {
    debug: debugColor,
    info: infoColor,
    warning: warningColor,
    error: errorColor,
    critical: criticalColor,
  };
  const levelColor = levelColorMap[flag.level as keyof typeof levelColorMap];

  return (
    <Card mb={2}>
      <Link to={'/hub/' + flag.hub + '/flag/' + flag.id} style={{ width: '100%' }}>
        <CardBody>
          <SimpleGrid columns={4} spacing={1} justifyItems={'center'} alignItems={'center'}>
            <Text fontSize="xl" fontWeight="bold">
              <Badge fontSize="0.8em" color={'white'} bg={levelColor}>
                {flag.level}
              </Badge>
            </Text>
            <Text ml={'1'} gridColumn="span 2">
              {flag.message && flag.message.length > 30 ? flag.message.substring(0, 30) + '...' : flag.message}
            </Text>
            <Button w={'100%'}>{t('show_uppercase')}</Button>
          </SimpleGrid>
        </CardBody>
      </Link>
    </Card>
  );
}
