/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useState } from 'react';
import { iquColor, whiteColor } from '../../styles';
import { SchemaModelEditorFields } from './SchemaModelEditorFields';

export function SchemaModelEditor({
  schema,
  data,
  setData,
  onSave,
  onCancel,
  useTabs = true,
  field_override,
  blackList,
}: {
  schema: { properties: Record<string, any>; $defs?: Record<string, any> };
  data: Record<string, any>;
  setData: (data: Record<string, any>) => void;
  onSave: () => Promise<string>;
  onCancel?: () => void;
  useTabs?: boolean;
  field_override?: Record<string, React.ReactNode>;
  blackList?: string[];
}) {
  const [fieldErrors, setFieldErrors] = useState<Record<string, string[]>>({});
  const singleProps: [string, any][] = [];
  const complexProps: [string, any][] = [];

  Object.entries(schema.properties || {}).forEach(([key, value]) => {
    if (
      ('$ref' in value || value.type === 'object' || value.type === 'array') &&
      hasProperties(value, schema.$defs || {})
    ) {
      complexProps.push([key, value]);
    } else {
      singleProps.push([key, value]);
    }
  });

  async function saveClick() {
    onSave().then((res: any) => {
      setFieldErrors(res?.response?.data?.error);
    });
  }

  return (
    <Box w={'100%'} mt={6}>
      {useTabs ? (
        <Tabs w={'100%'} variant="enclosed">
          <TabList w={'100%'}>
            {singleProps.length > 0 && <Tab>{t('general')}</Tab>}
            {complexProps.map(([key, value]) => (
              <Tab key={key}>{t(key)}</Tab>
            ))}
          </TabList>
          <TabPanels w={'100%'}>
            {singleProps.length > 0 && (
              <TabPanel>
                <VStack spacing={4} align="stretch">
                  {singleProps.map(([key, value]) => (
                    <React.Fragment key={key}>
                      {field_override?.[key] ? (
                        <>
                          {field_override[key]}
                          {Object.entries(fieldErrors)
                            .filter(([path]) => path.startsWith(key))
                            .flatMap(([_, errors]) => errors)
                            .map((error, idx) => (
                              <Box key={idx} color="red.500" fontSize="sm">
                                {error}
                              </Box>
                            ))}
                        </>
                      ) : (
                        SchemaModelEditorFields(
                          { properties: { [key]: value } },
                          '',
                          schema,
                          data,
                          setData,
                          field_override,
                          blackList,
                          fieldErrors,
                        )
                      )}
                    </React.Fragment>
                  ))}
                </VStack>
              </TabPanel>
            )}
            {complexProps.map(([key, value]) => (
              <TabPanel key={key} w={'100%'}>
                <VStack spacing={4} align="stretch">
                  {field_override?.[key] ? (
                    <>
                      {field_override[key]}
                      {Object.entries(fieldErrors)
                        .filter(([path]) => path.startsWith(key))
                        .flatMap(([_, errors]) => errors)
                        .map((error, idx) => (
                          <Box key={idx} color="red.500" fontSize="sm">
                            {error}
                          </Box>
                        ))}
                    </>
                  ) : (
                    SchemaModelEditorFields(
                      { properties: { [key]: value } },
                      '',
                      schema,
                      data,
                      setData,
                      field_override,
                      blackList,
                      fieldErrors,
                    )
                  )}
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <VStack spacing={4} align="stretch">
          {singleProps.map(([key, value]) => (
            <React.Fragment key={key}>
              {SchemaModelEditorFields(
                { properties: { [key]: value } },
                '',
                schema,
                data,
                setData,
                field_override,
                blackList,
                fieldErrors,
              )}
            </React.Fragment>
          ))}
          {complexProps.map(([key, value]) => (
            <React.Fragment key={key}>
              {SchemaModelEditorFields(
                { properties: { [key]: value } },
                '',
                schema,
                data,
                setData,
                field_override,
                blackList,
                fieldErrors,
              )}
            </React.Fragment>
          ))}
        </VStack>
      )}

      <HStack justify="flex-end" mt={4}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button bg={iquColor} color={whiteColor} onClick={saveClick}>
          Save
        </Button>
      </HStack>
    </Box>
  );
}

function hasProperties(value: any, defs: Record<string, any>): boolean {
  if ('$ref' in value && defs) {
    const defName = value.$ref.replace('#/$defs/', '');
    const defSchema = defs[defName];
    return defSchema && defSchema.properties && Object.keys(defSchema.properties).length > 0;
  }
  if (value.type === 'object' && value.properties) {
    return Object.keys(value.properties).length > 0;
  }
  if (value.type === 'array' && value.items) {
    return hasProperties(value.items, defs);
  }
  return false;
}

export function initializeDefaults(currentSchema: { properties: Record<string, any> }, schema: any) {
  const result: Record<string, any> = {};
  if (!currentSchema || !currentSchema.properties) return result;
  Object.entries(currentSchema.properties).forEach(([propKey, propValue]) => {
    if (propValue && typeof propValue === 'object' && 'default' in propValue) {
      result[propKey] = propValue.default;
    } else if (
      typeof propValue === 'object' &&
      propValue !== null &&
      (propValue.type === 'object' || '$ref' in propValue)
    ) {
      result[propKey] = initializeDefaultsNested(propValue, schema);
    } else if (typeof propValue === 'object' && propValue.type === 'array') {
      result[propKey] = [];
    } else {
      result[propKey] = '';
    }
  });
  return result;
}

function initializeDefaultsNested(property: any, schema: any) {
  if ('$ref' in property && schema) {
    const defName = property.$ref.replace('#/$defs/', '');
    const defSchema = schema.$defs?.[defName];
    return initializeDefaults(defSchema, schema);
  }
  if (property.properties) {
    return initializeDefaults(property, schema);
  }
  return {};
}
