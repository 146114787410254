import {
  Box,
  Center,
  Input,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { AllHubs } from '../../apiService';
import { HubCard } from '../../components/hub/HubCard';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { HubsDTO } from '../../dto';

export function AllHubsPage() {
  const [hubs, setHubs] = useState<HubsDTO>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    AllHubs().then((r) => setHubs(r));
  }, []);

  if (!hubs) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Center>
        <VStack width={'100%'}>
          <Text size={'xl'} as={'b'}>
            {t('search')}
          </Text>
          <Input
            type="text"
            placeholder={t('Search_by')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            marginBottom="16px"
            width={{ base: '95%', md: '30%' }}
          />
        </VStack>
      </Center>
      <Tabs size="md" variant="enclosed" width="100%">
        <TabList>
          {Object.keys(hubs).map((hubType) => (
            <Tab key={hubType}>{t(hubType)}</Tab>
          ))}
        </TabList>
        <TabPanels ml="2.3%">
          {Object.keys(hubs).map((hubType) => (
            <TabPanel key={hubType}>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                {hubs[hubType].map((hub) => (
                  <HubCard key={hub.hub_id} hub={hub} hub_type={hubType} />
                ))}
              </SimpleGrid>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
