/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { initializeDefaults } from './SchemaModelEditor';

interface SchemaProperty {
  type?: 'string' | 'number' | 'integer' | 'boolean' | 'array' | 'object';
  enum?: any[];
  minimum?: number;
  maximum?: number;
  exclusiveMinimum?: number;
  exclusiveMaximum?: number;
  properties?: Record<string, SchemaProperty>;
  items?: SchemaProperty | SchemaProperty[]; // Allow for array of items
  $ref?: string;
}

interface Schema {
  properties: Record<string, SchemaProperty>;
  required?: string[];
  $defs?: Record<string, SchemaProperty>;
}

export function SchemaModelEditorFields(
  currentSchema: { properties: Record<string, any> } | null,
  parentPath = '',
  schema: Schema,
  data: Record<string, any>,
  setData: (arg0: { [x: string]: any }) => void,
  field_override?: Record<string, React.ReactNode>,
  blacklist: string[] = [],
  fieldErrors: Record<string, string[]> = {},
) {
  function handleChange(path: string, value: any) {
    const keys = path.split('.');
    let propertySchema: SchemaProperty | undefined = schema;

    for (const key of keys) {
      if (!propertySchema) {
        console.error('Property schema not found for key:', key);
        return;
      }

      if (propertySchema.type === 'array' && propertySchema.items) {
        propertySchema = Array.isArray(propertySchema.items) ? propertySchema.items[0] : propertySchema.items;

        if (propertySchema && '$ref' in propertySchema && propertySchema.$ref) {
          const refName: string = propertySchema.$ref.replace('#/$defs/', '');
          propertySchema = schema.$defs?.[refName];
        }

        if (!isNaN(parseInt(key, 10))) continue;
      }

      if (propertySchema && '$ref' in propertySchema && propertySchema.$ref) {
        const refName: string = propertySchema.$ref.replace('#/$defs/', '');
        propertySchema = schema.$defs?.[refName];
      }

      if (!propertySchema?.properties || !propertySchema.properties[key]) {
        console.error('Property not found:', key, 'in schema:', propertySchema);
        return;
      }

      propertySchema = propertySchema.properties[key];
    }

    if (!propertySchema) {
      console.error('Final property schema not found:', path);
      return;
    }

    if (propertySchema.type === 'number' || propertySchema.type === 'integer') {
      value = propertySchema.type === 'integer' ? parseInt(value, 10) : parseFloat(value);

      if (isNaN(value)) return;
      if (propertySchema.minimum !== undefined && value < propertySchema.minimum) return;
      if (propertySchema.exclusiveMinimum !== undefined && value <= propertySchema.exclusiveMinimum) return;
      if (propertySchema.maximum !== undefined && value > propertySchema.maximum) return;
      if (propertySchema.exclusiveMaximum !== undefined && value >= propertySchema.exclusiveMaximum) return;
    } else if (propertySchema.type === 'boolean') {
      value = Boolean(value);
    } else if (propertySchema.type === 'string') {
      value = String(value);
    } else if (propertySchema.enum && !propertySchema.enum.includes(value)) {
      return;
    }

    const updated = setValueByPath(data, path, value);
    setData(updated);
  }

  const doesPathMatch = (path: string, wildcardPath: string) => {
    const pathParts = path.split('.');
    const wildcardParts = wildcardPath.split('.');
    if (pathParts.length !== wildcardParts.length) return false;

    return pathParts.every((part, index) => wildcardParts[index] === '#' || wildcardParts[index] === part);
  };

  if (!currentSchema || !currentSchema.properties) return null;
  return Object.entries(currentSchema.properties)
    .filter(([key]) => {
      const currentPath = parentPath ? `${parentPath}.${key}` : key;
      return !blacklist.some((blacklistedPath) => doesPathMatch(currentPath, blacklistedPath));
    })
    .map(([key, value]) => {
      const currentPath = parentPath ? `${parentPath}.${key}` : key;

      const matchingOverrideKey = Object.keys(field_override || {}).find((overrideKey) => {
        const match = doesPathMatch(currentPath, overrideKey);
        return match;
      });
      if (matchingOverrideKey) {
        return (
          <>
            {field_override?.[matchingOverrideKey]}
            {Object.entries(fieldErrors)
              .filter(([path]) => path.startsWith(currentPath))
              .flatMap(([_, errors]) => errors)
              .map((error, idx) => (
                <Box key={idx} color="red.500" fontSize="sm">
                  {error}
                </Box>
              ))}
          </>
        );
      }

      if ('$ref' in value) {
        const defName = value.$ref.replace('#/$defs/', '');
        const defSchema = schema.$defs?.[defName];
        if (defSchema?.enum) {
          return (
            <FormControl key={currentPath}>
              <FormLabel>{t(key)}</FormLabel>
              <Select
                value={String(getValueByPath(data, currentPath) ?? '')}
                onChange={(e) => handleChange(currentPath, e.target.value)}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {defSchema.enum.map((opt: any) => (
                  <option key={opt} value={opt}>
                    {t(opt)}
                  </option>
                ))}
              </Select>
              {fieldErrors[currentPath]?.map((error, idx) => (
                <Box key={idx} color="red.500" fontSize="sm">
                  {error}
                </Box>
              ))}
            </FormControl>
          );
        }
        return (
          <VStack key={currentPath} align="stretch">
            {defSchema?.properties &&
              SchemaModelEditorFields(
                { properties: defSchema.properties },
                currentPath,
                schema,
                data,
                setData,
                field_override,
                blacklist,
                fieldErrors,
              )}
          </VStack>
        );
      }

      if (value.type === 'object') {
        return (
          <VStack key={currentPath} align="stretch">
            <Heading size="md">{t(key)}</Heading>
            {SchemaModelEditorFields(value, currentPath, schema, data, setData, field_override, blacklist, fieldErrors)}
          </VStack>
        );
      }

      if (value.type === 'array' && value.items && value.uniqueItems) {
        const defName = value.items.$ref?.replace('#/$defs/', '');
        const defSchema = defName ? schema.$defs?.[defName] : null;

        if (defSchema?.enum) {
          return (
            <VStack key={currentPath} align="stretch">
              <FormLabel>{t(key)}</FormLabel>
              {defSchema.enum.map((item: string) => (
                <Checkbox
                  key={item}
                  isChecked={
                    Array.isArray(getValueByPath(data, currentPath)) && getValueByPath(data, currentPath).includes(item)
                  }
                  onChange={(e) => {
                    const arr = getValueByPath(data, currentPath);
                    const updatedArr = Array.isArray(arr) ? [...arr] : [];
                    if (e.target.checked) {
                      updatedArr.push(item);
                    } else {
                      const index = updatedArr.indexOf(item);
                      if (index > -1) updatedArr.splice(index, 1);
                    }
                    const updated = setValueByPath(data, currentPath, updatedArr);
                    setData(updated);
                  }}
                >
                  {t(item)}
                </Checkbox>
              ))}

              {fieldErrors[currentPath]?.map((error, idx) => (
                <Box key={idx} color="red.500" fontSize="sm">
                  {error}
                </Box>
              ))}
            </VStack>
          );
        }
      }

      if (value.type === 'array' && value.items) {
        const defName = value.items.$ref?.replace('#/$defs/', '');
        const defSchema = defName ? schema.$defs?.[defName] : value.items;
        return (
          <VStack key={currentPath} align="stretch">
            <FormLabel>{t(key)}</FormLabel>
            <Button
              onClick={() => {
                const arr = getValueByPath(data, currentPath);
                const newArr = Array.isArray(arr) ? [...arr] : [];

                const newItem =
                  value.items.type === 'string' ? '' : defSchema ? initializeDefaults(defSchema, schema) : '';
                newArr.push(newItem);

                const updated = setValueByPath(data, currentPath, newArr);
                setData(updated);
              }}
            >
              {t('add_item')}
            </Button>
            {fieldErrors[currentPath]?.map((error, idx) => (
              <Box key={idx} color="red.500" fontSize="sm">
                {error}
              </Box>
            ))}
            {Array.isArray(getValueByPath(data, currentPath)) &&
              getValueByPath(data, currentPath).map((item: any, idx: number) => {
                const itemPath = currentPath + '.' + idx;
                if (typeof item === 'string') {
                  return (
                    <>
                      <HStack key={itemPath}>
                        <Input
                          value={item}
                          onChange={(e) => {
                            const arr = getValueByPath(data, currentPath);
                            const updatedArr = Array.isArray(arr) ? [...arr] : [];
                            updatedArr[idx] = e.target.value;
                            const updated = setValueByPath(data, currentPath, updatedArr);
                            setData(updated);
                          }}
                        />
                        <Button
                          onClick={() => {
                            const arr = getValueByPath(data, currentPath);
                            const updatedArr = Array.isArray(arr) ? [...arr] : [];
                            updatedArr.splice(idx, 1);
                            const updated = setValueByPath(data, currentPath, updatedArr);
                            setData(updated);
                          }}
                        >
                          {t('remove')}
                        </Button>
                      </HStack>
                      {fieldErrors[currentPath]?.map((error, idx) => (
                        <Box key={idx} color="red.500" fontSize="sm">
                          {error}
                        </Box>
                      ))}
                    </>
                  );
                }
                return (
                  <VStack key={itemPath} pl={4} border="1px solid #ccc" p={2}>
                    {defSchema ? (
                      SchemaModelEditorFields(
                        defSchema,
                        itemPath,
                        schema,
                        data,
                        setData,
                        field_override,
                        blacklist,
                        fieldErrors,
                      )
                    ) : (
                      <Input
                        value={item || ''}
                        onChange={(e) => {
                          const arr = getValueByPath(data, currentPath);
                          const updatedArr = Array.isArray(arr) ? [...arr] : [];
                          updatedArr[idx] = e.target.value;
                          const updated = setValueByPath(data, currentPath, updatedArr);
                          setData(updated);
                        }}
                      />
                    )}
                    {fieldErrors[currentPath]?.map((error, idx) => (
                      <Box key={idx} color="red.500" fontSize="sm">
                        {error}
                      </Box>
                    ))}
                  </VStack>
                );
              })}
          </VStack>
        );
      }

      if (value.enum) {
        return (
          <FormControl key={currentPath}>
            <FormLabel>{t(key)}</FormLabel>
            <Select
              value={String(getValueByPath(data, currentPath) ?? '')}
              onChange={(e) => handleChange(currentPath, e.target.value)}
            >
              <option value="" disabled>
                Select an option
              </option>
              {value.enum.map((opt: any) => (
                <option key={opt} value={opt}>
                  {t(opt)}
                </option>
              ))}
            </Select>
            {fieldErrors[currentPath]?.map((error, idx) => (
              <Box key={idx} color="red.500" fontSize="sm">
                {error}
              </Box>
            ))}
          </FormControl>
        );
      }
      if (value.type === 'boolean') {
        return (
          <FormControl key={currentPath}>
            <FormLabel>{t(key)}</FormLabel>
            <Checkbox
              isChecked={Boolean(getValueByPath(data, currentPath))}
              onChange={(e) => handleChange(currentPath, e.target.checked)}
            >
              {t(key)}
            </Checkbox>
            {fieldErrors[currentPath]?.map((error, idx) => (
              <Box key={idx} color="red.500" fontSize="sm">
                {error}
              </Box>
            ))}
          </FormControl>
        );
      }
      if (value.type === 'number' || value.type === 'integer') {
        return (
          <FormControl key={currentPath}>
            <FormLabel>{t(key)}</FormLabel>
            <Input
              type="number"
              value={String(getValueByPath(data, currentPath) ?? '')}
              onChange={(e) => handleChange(currentPath, parseFloat(e.target.value))}
            />
            {fieldErrors[currentPath]?.map((error, idx) => (
              <Box key={idx} color="red.500" fontSize="sm">
                {error}
              </Box>
            ))}
          </FormControl>
        );
      }
      if (value.type === 'string' && (value.format === 'textarea' || key.toLowerCase().includes('comment'))) {
        return (
          <FormControl key={currentPath}>
            <FormLabel>{t(key)}</FormLabel>
            <Textarea
              value={String(getValueByPath(data, currentPath) ?? '')}
              onChange={(e) => handleChange(currentPath, e.target.value)}
            />
            {fieldErrors[currentPath]?.map((error, idx) => (
              <Box key={idx} color="red.500" fontSize="sm">
                {error}
              </Box>
            ))}
          </FormControl>
        );
      }
      return (
        <FormControl key={currentPath}>
          <FormLabel>{t(key)}</FormLabel>
          <Input
            value={String(getValueByPath(data, currentPath) ?? '')}
            onChange={(e) => handleChange(currentPath, e.target.value)}
          />
          {fieldErrors[currentPath]?.map((error, idx) => (
            <Box key={idx} color="red.500" fontSize="sm">
              {error}
            </Box>
          ))}
        </FormControl>
      );
    });
}

function getValueByPath(obj: Record<string, any>, path: string) {
  return path.split('.').reduce((acc, key) => {
    if (acc && acc[key] !== undefined) {
      return acc[key];
    }
    return null;
  }, obj);
}

function setValueByPath(obj: Record<string, any>, path: string, value: any) {
  const keys = path.split('.');
  const copy = structuredClone(obj); // Deep copy to avoid mutation
  let temp = copy;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!temp[key] || typeof temp[key] !== 'object') {
      temp[key] = {}; // Ensure intermediate keys are objects
    }
    temp = temp[key];
  }

  temp[keys[keys.length - 1]] = value;
  return copy;
}
