import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { CustomerDataDTO } from '../../dto';

interface HubCardProp {
  customer: CustomerDataDTO;
}

export function CustomerCard({ customer }: HubCardProp) {
  return (
    <Card height={'100%'} width={'100%'} rounded={'lg'} p={6}>
      <CardHeader p={0}>
        <Center>
          <VStack>
            <Heading fontSize={'xl'} fontFamily={'body'}>
              {customer.company_name}
            </Heading>
            <Text fontSize={'xs'}>{customer.customer_id}</Text>
            <Text fontSize={'sm'} color={'gray.500'}></Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider />
      <CardBody p={4}>
        <Center>
          <HStack>
            <Badge px={2} py={1} fontWeight={'400'}>
              {`${customer.street}
              ${customer.house_number}, ${customer.post_code}
              ${customer.city}`}
            </Badge>
          </HStack>
        </Center>
      </CardBody>
      <CardFooter p={0}>
        <Center w={'100%'}>
          <Box w={'100%'}>
            <Link to={`/customers/${customer.customer_id}`}>
              <Button width={'100%'} fontSize={'sm'} rounded={'full'}>
                {t('manage')}
              </Button>
            </Link>
          </Box>
        </Center>
      </CardFooter>
    </Card>
  );
}
