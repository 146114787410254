import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  SimpleGrid,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { t } from 'i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import { GetCommands, SendHubCommand } from '../../apiService';
import { commandConfig } from '../hub/info/Commands';

function useSubmitCommandAlert() {
  const disclosure = useDisclosure();
  const cancelRef = React.useRef(null);
  const [title, setTitle] = useState('');
  const [onSubmitCallback, setOnSubmitCallback] = useState(() => () => {});

  return {
    openSubmitCommandAlert: (title: string, onSubmitCallback: () => void) => {
      setTitle(title);
      setOnSubmitCallback(() => onSubmitCallback);
      disclosure.onOpen();
    },
    SubmitCommandAlert: () => (
      <AlertDialog isOpen={disclosure.isOpen} leastDestructiveRef={cancelRef} onClose={disclosure.onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <AlertDialogBody>{t('are_you_sure')}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={disclosure.onClose}>
                {t('cancel')}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  disclosure.onClose();
                  onSubmitCallback();
                }}
                ml={3}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    ),
  };
}

interface TaskCardInterface {
  id: string;
  additionalCommands?: ((props: {
    sendCommand: (command: string, commandName: string) => void;
    openSubmitCommandAlert: (title: string, onSubmitCallback: () => void) => void;
  }) => ReactNode)[];
  type: string;
}

export function TaskCard({ id, type }: TaskCardInterface) {
  const { openSubmitCommandAlert, SubmitCommandAlert } = useSubmitCommandAlert();
  const toast = useToast();
  const [commands, setCommands] = useState<string[]>();

  useEffect(() => {
    let _type = type;
    if (type === 'Core') {
      _type = 'coremodel';
    }
    if (type === 'SIC') {
      _type = 'sicmodel';
    }

    GetCommands(_type).then((r) => {
      setCommands(r);
    });
  }, [type]);

  const sendCommand = (command: string, commandName: string) => {
    SendHubCommand(id, command).then(() => {
      toast({
        title: commandName + ' ' + t('is_applied_lowercase'),
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  if (!commands) {
    return (
      <Center h={'100%'}>
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <SubmitCommandAlert />
      <SimpleGrid minChildWidth="40%" spacing="20px" paddingTop={'20px'} paddingInline={'20px'}>
        {Object.values(commands).map((key) =>
          commandConfig[key].component({ hubId: id, openSubmitCommandAlert, sendCommand, commandName: key }),
        )}
      </SimpleGrid>
    </>
  );
}
