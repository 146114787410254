import { Box, Divider, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { CustomerDataDTO } from '../../dto';
import { IsOnline } from '../TimeUtils/TimeUtils';

interface CustomerInfoInterface {
  customer: CustomerDataDTO;
}

export function CustomerInfo({ customer }: CustomerInfoInterface) {
  return (
    <VStack spacing={4} p={4}>
      <Heading size="lg">{t('info_uppercase')}</Heading>
      <Text size={'s'}>{IsOnline(customer.last_contact) ? t('online') : t('offline')}</Text>
      <Text fontSize="sm" color="gray.500">
        ID: {customer.customer_id}
      </Text>
      <Divider />

      <SimpleGrid columns={2} spacing={4} w="100%">
        <Box>
          <Text fontWeight="bold">Industry:</Text>
          <Text>{customer.industry}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Email:</Text>
          <Text>{customer.email}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Phone:</Text>
          <Text>{customer.phone}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Address:</Text>
          <Text>
            {`${customer.street}
              ${customer.house_number}, ${customer.post_code}
              ${customer.city}`}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Latitude:</Text>
          <Text>{customer.latitude}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Longitude:</Text>
          <Text>{customer.longitude}</Text>
        </Box>
      </SimpleGrid>

      {customer.comment && (
        <>
          <Divider />
          <Box w="100%">
            <Text fontWeight="bold">Comment:</Text>
            <Text>{customer.comment}</Text>
          </Box>
        </>
      )}
    </VStack>
  );
}
