import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { HubDTO } from '../../dto';
import { StatusHubIcon } from '../TimeUtils/TimeUtils';

interface HubCardProp {
  hub: HubDTO;
  hub_type: string;
}

export function HubCard({ hub, hub_type }: HubCardProp) {
  return (
    <Card height={'100%'} maxH={'250px'} width={'100%'} rounded={'lg'} p={6}>
      <CardHeader>
        <Center position={'absolute'} top={'0%'} left={'50%'} transform={'translate(-50%, 0)'}>
          <Badge fontWeight={'400'}>{t(hub_type)}</Badge>
        </Center>
        <Center>
          <StatusHubIcon hub={hub} />
          <VStack>
            <Heading fontSize={'xl'} fontFamily={'body'}>
              {hub.hub_name}
            </Heading>
            <Text fontSize={'xs'}>{hub.hub_id}</Text>
            <Text fontSize={'sm'} color={'gray.500'}></Text>
          </VStack>
        </Center>
      </CardHeader>
      <Divider />
      <CardBody>
        <Center>
          <HStack>
            <Badge px={2} py={1} fontWeight={'400'}>
              {hub.date_added}
            </Badge>
          </HStack>
        </Center>
      </CardBody>
      <Center>
        <Box width={'100%'}>
          <Link to={`/hub/${t(hub_type)}/${hub.hub_id}`}>
            <Button width={'100%'} fontSize={'sm'} rounded={'full'}>
              {t('manage')}
            </Button>
          </Link>
        </Box>
      </Center>
    </Card>
  );
}
