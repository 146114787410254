import { AbsoluteCenter, Center, Flex, Image, Spinner } from '@chakra-ui/react';
import IQULogo from '../../assets/images/iQU_transparent.png';
import { iquColor } from '../../styles';

export function LoadingSpinner() {
  return (
    <Flex height="90vh">
      <AbsoluteCenter>
        <Center>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color={iquColor} size="xl" />
        </Center>
        <Center>
          <Image boxSize="30%" src={IQULogo} />
        </Center>
      </AbsoluteCenter>
    </Flex>
  );
}
